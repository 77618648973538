<template>
  <Page title="Reset password">
    <div class="c-resetPassword">
      <Accordion>
        <AccordionItem
          :title="$t('widgets.forgotPassword.accordionTitle')"
          icon="password"
          keep-open
        >
          <template #accordion-content>
            <form
              @submit.prevent="handleSubmit"
              @invalid.capture.prevent="validate"
            >
              <BaseTextBox
                v-for="(item, index) in fields"
                :key="item.id"
                :label="$t(item.label)"
                :id="item.id"
                :name="item.name"
                :type="item.type"
                v-model="inputParams[index]"
                :ref="el => { inputEl[item.id] = el }"
                :required="item.required"
                :placeholder="$t(item.placeholder)"
              />
              <BaseButton
                size="small"
                variant="solid"
                color="primary"
                type="submit"
                :disabled="!inputParams[0] || !inputParams[1]
                  || inputParams[0] !== inputParams[1]"
                :btn-label="$t('widgets.forgotPassword.resetPasswordBtnText')"
              />
            </form>
          </template>
        </AccordionItem>
      </Accordion>
    </div>
  </Page>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Page from '@/components/Page.vue';
import Accordion from '@/components/common/Accordion/Accordion.vue';
import AccordionItem from '@/components/common/Accordion/AccordionItem.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';

const store = useStore();
const route = useRoute();
const inputParams = ref([]);
const inputEl = ref([]);

const fields = computed(() => [ {
  label: 'widgets.forgotPassword.resetPasswordFields.newPassword.label',
  name: 'newPassword',
  id: 'newPassword',
  placeholder: 'widgets.forgotPassword.resetPasswordFields.newPassword.placeholder',
  type: 'password',
  required: true,
}, {
  label: 'widgets.forgotPassword.resetPasswordFields.confirmPassword.label',
  name: 'confirmPassword',
  id: 'confirmPassword',
  placeholder: 'widgets.forgotPassword.resetPasswordFields.confirmPassword.placeholder',
  type: 'password',
  required: true,
} ]);

const validate = evt => {
  inputEl.value[evt.target.id].validateForm(evt);
};

const handleSubmit = () => {
  store.dispatch('auth/setNewPassword', {
    token: route.params.token,
    password: inputParams.value[0],
  });
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-resetPassword {
  @include mx.d-flex(center, center, true);

  width: 100%;
  height: 90vh;

  &::v-deep(.c-baseButton) {
    width: 100%;
  }
}
</style>
